<template lang="html">
    <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown"
           aria-haspopup="true" aria-expanded="false">
            Admin
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            <a class="dropdown-item" :href='getAbsoluteAction("FlashBoxMgmt", "Material")'>FlashBox Mgmt</a>
            <a class="dropdown-item" :href='getAbsoluteAction("ImportVideo", "Video")'>匯入影片</a>
            <a class="dropdown-item" :href='getAbsoluteAction("VideoImport", "Manage")'>影片分類</a>
            <a class="dropdown-item" :href='getAbsoluteAction("InvitationApprovalBS", "Manage")'>使用者管理</a>
            <a class="dropdown-item" :href='getAbsoluteAction("TicketMgmt", "Manage")'>票券管理</a>
            <a class="dropdown-item" :href='getAbsoluteAction("LearningStatistic", "Lex")'>學習摘要</a>
            <a class="dropdown-item" :href='getAbsoluteAction("SeriesMgmt", "Speaking")'>系列課程管理</a>
            <a class="dropdown-item" :href='getAbsoluteAction("UserTranslationStates", "Material")'>教材翻譯進度</a>
            <a class="dropdown-item" :href='getAbsoluteAction("ListOutline", "Speaking")'>課程大綱列表</a>
        </div>
    </li>
</template>

<script>
    import { ghelper } from '@/lib/ghelper';

    export default {
        components: {
        },
        props: {
        },
        data: function () {
            return {
            };
        },
        methods: {
            getAbsoluteAction: function (action, ctrl) {
                return ghelper.getActionUrl(action, ctrl);
            },
        },
        mounted: function () {
        },
        computed: {
        },
    };
</script>

<style lang="css">
</style>